<template>
  <el-card id="product">
    <header>
    
      <div class="search">
       <div class="left">
        <h2 style="display: flex; align-items: center">
        产品管理<span v-if="showTitleText"
          >(可以把公司的经营项目或者服务内容，设置为产品，员工在新增合同的时候可以进行关联)</span
        >
        <el-tooltip
            class="item"
            effect="dark"
            content="点击查看详情说明"
            placement="top"
          >
            <img
              v-if="!showTitleText"
              src="@/assets/images/what.png"
              alt=""
              style="width: 30px; height: 30px; cursor: pointer"
              @click="showTitleText = true"
            />
          </el-tooltip>
      </h2>
        <!-- <div class="left">
          <div class="left-wrap">
            <span>号码</span>
            <el-input
              v-model="activePhone"
              placeholder="请输入号码"
              size="mini"
            ></el-input>
          </div>
          <div class="left-wrap">
            <span>企业</span>
            <el-input
              v-model="company"
              placeholder="请输入企业名称"
              size="mini"
            ></el-input>
          </div>
          <div class="left-wrap" v-if="realname == 'admin'">
            <span>企业</span>
            <el-input
              v-model="company"
              placeholder="请输入公司名称"
              filterable
              size="mini"
            ></el-input>
          </div>
          <div class="left-wrap" v-if="roleName == '组长'">
            <span>员工</span>
            <el-select
              v-model="id1"
              placeholder="请选择员工"
              filterable
              size="mini"
            >
              <el-option
                v-for="item in underUser"
                :key="item.id"
                :label="item.realname"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <div
            class="left-wrap"
            v-if="roleName == '管理员' || roleName == '企业'"
          >
            <span>部门</span>
            <el-select
              v-model="departId"
              placeholder="请选择部门"
              filterable
              size="mini"
            >
              <el-option
                v-for="item in underDepart"
                :key="item.id"
                :label="item.departName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <div
            class="left-wrap"
            v-if="roleName == '管理员' || roleName == '企业'"
          >
            <span>员工</span>
            <el-select
              v-model="userId"
              placeholder="请选择员工"
              filterable
              size="mini"
            >
              <el-option
                v-for="item in underUser"
                :key="item.id"
                :label="item.realname"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <div class="left-wrap">
            <span>创建时间</span>
            <el-date-picker
              v-model="createTime"
              type="datetimerange"
              range-separator="-"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              align="center"
              size="mini"
            >
            </el-date-picker>
          </div>
          <div class="left-wrap">
            <span>拨打时间</span>
            <el-date-picker
              v-model="changeTime"
              type="datetimerange"
              range-separator="-"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              align="center"
              size="mini"
            >
            </el-date-picker>
          </div>
          <div class="left-wrap">
            <span>更新时间</span>
            <el-date-picker
              v-model="getTime"
              type="datetimerange"
              range-separator="-"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              align="center"
              size="mini"
            >
            </el-date-picker>
          </div>
        </div> -->
        </div> 
        <div class="right">
          <!-- <el-button
            type="primary"
            icon="el-icon-search"
            @click="handleSearch"
            size="mini"
            class="searchBtn"
            >查找</el-button
          >
          <el-button
            class="reset"
            type="primary"
            icon="el-icon-refresh"
            @click="reset()"
            size="mini"
            >重置</el-button
          > -->
          <el-button
            type="primary"
            icon="el-icon-circle-plus-outline"
            @click="addProduct()"
            size="mini"
            class="addNums"
            v-if="roleName == '企业'"
            >新增产品</el-button
          >
        </div>
      </div>
    </header>
    <hr>
    <main>
      <el-table
        :data="tableData"
        border
        class="table"
        ref="multipleTable"
        header-cell-class-name="table-header"
      >
        <el-table-column
          type="selection"
          width="55"
          align="center"
        ></el-table-column>
        <!-- <el-table-column
          prop="companyId"
          label="公司ID"
          align="center"
        ></el-table-column> -->
        <el-table-column
          prop="createUserId"
          label="创建人ID"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="createUser"
          label="创建人姓名"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="productCoding"
          label="产品编码"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="productName"
          label="产品名字"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="procuctPrice"
          label="产品价格"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="unit"
          label="产品单位"
          align="center"
        ></el-table-column>
        <el-table-column prop="status" label="状态" align="center">
          <template #default="scope">
            <span v-if="scope.row.status == 1">上架</span>
            <span v-if="scope.row.status == 2">下架</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="productDescribe"
          label="产品描述"
          align="center"
        ></el-table-column>
        <el-table-column prop="createDate" label="创建时间" align="center">
          <template v-slot="scope">
            <div>
              {{ getYMDHMS("YYYY-mm-dd HH:MM:SS", scope.row.createDate) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="updatea" label="更新时间" align="center">
          <template v-slot="scope">
            <div>
              {{ getYMDHMS("YYYY-mm-dd HH:MM:SS", scope.row.updatea) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          width="180"
          align="center"
          fixed="right"
          v-if="roleName == '企业'"
        >
          <template #default="scope">
            <el-button type="primary" @click="update(scope)" size="mini"
              >编辑</el-button
            >
            <el-button type="danger" @click="del(scope)" size="mini"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!--分页  -->
      <div class="pagination">
        <el-pagination
          background
          layout="total, prev, pager, next,sizes,jumper"
          :current-page="pageIndex"
          :page-size="pageSize"
          :page-sizes="[10, 20, 50, 100, 200]"
          :total="pageTotal"
          @size-change="handleSizeChange"
          @current-change="handlePageChange"
        ></el-pagination>
      </div>
    </main>
    <!-- 新增产品弹出框 -->
    <el-dialog title="新增产品" :visible.sync="addProductVisible">
      <el-form
        ref="addForm"
        :model="addForm"
        label-width="100px"
        class="update"
      >
        <el-form-item label="产品名字">
          <el-input
            v-model="addForm.productName"
            placeholder="请输入产品名字"
          ></el-input>
        </el-form-item>
        <el-form-item label="产品编码">
          <el-input
            v-model="addForm.productCoding"
            placeholder="请输入产品编码"
          ></el-input>
        </el-form-item>
        <el-form-item label="产品价格">
          <el-input
            v-model="addForm.procuctPrice"
            placeholder="请输入产品价格"
          ></el-input>
        </el-form-item>
        <el-form-item label="产品描述">
          <el-input
            v-model="addForm.productDescribe"
            placeholder="请输入产品描述"
            type="textarea"
          ></el-input>
        </el-form-item>
        <el-form-item label="状态">
          <el-radio-group v-model="addForm.status" style="line-height: 30px">
            <el-radio :label="1">上架</el-radio>
            <el-radio :label="2">下架</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="单位">
          <el-input v-model="addForm.unit" placeholder="请输入单位"></el-input>
          <!-- <el-radio-group
            v-model="addFormUnit.unit"
            v-for="(item, index) in addFormUnit.radio"
            :key="index"
          >
            <el-radio :label="item.value"></el-radio>
          </el-radio-group> -->
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="addProductVisible = false"
            >取 消</el-button
          >
          <el-button type="primary" @click="addProductSubmit">确 定</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 编辑产品弹出框 -->
    <el-dialog title="编辑产品" :visible.sync="updateVisible">
      <el-form
        ref="updateForm"
        :model="updateForm"
        label-width="100px"
        class="update"
      >
        <el-form-item label="产品名字">
          <el-input
            v-model="updateForm.productName"
            placeholder="请输入产品名字"
          ></el-input>
        </el-form-item>
        <el-form-item label="产品编码">
          <el-input
            v-model="updateForm.productCoding"
            placeholder="请输入产品编码"
          ></el-input>
        </el-form-item>
        <el-form-item label="产品价格">
          <el-input
            v-model="updateForm.procuctPrice"
            placeholder="请输入产品价格"
          ></el-input>
        </el-form-item>
        <el-form-item label="产品描述">
          <el-input
            v-model="updateForm.productDescribe"
            placeholder="请输入产品描述"
            type="textarea"
          ></el-input>
        </el-form-item>
        <el-form-item label="状态">
          <el-radio-group v-model="updateForm.status" style="line-height: 30px">
            <el-radio :label="1">上架</el-radio>
            <el-radio :label="2">下架</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="单位">
          <el-input
            v-model="updateForm.unit"
            placeholder="请输入单位"
          ></el-input>
          <!-- <el-radio-group
            v-model="updateFormUnit.unit"
            v-for="(item, index) in updateFormUnit.radio"
            :key="index"
          >
            <el-radio :label="item.value"></el-radio>
          </el-radio-group> -->
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="updateVisible = false"
            >取 消</el-button
          >
          <el-button type="primary" @click="updateSubmit">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </el-card>
</template>

<script>
import { ElMessage } from "@/assets/js/elementui-api";
let Message = new ElMessage();
import {
  productAdd,
  productDelete,
  productUpdate,
  productSelect,
} from "@/api/product";
export default {
  data() {
    return {
      showTitleText:false,
      roleName: null,
      // updateFormUnit: {
      //   radio: [
      //     { value: "个" },
      //     { value: "块" },
      //     { value: "只" },
      //     { value: "把" },
      //     { value: "枚" },
      //     { value: "瓶" },
      //     { value: "盒" },
      //     { value: "台" },
      //     { value: "吨" },
      //     { value: "千克" },
      //     { value: "米" },
      //     { value: "箱" },
      //     { value: "套" },
      //   ],
      // },
      // addFormUnit: {
      //   radio: [
      //     { value: "个" },
      //     { value: "块" },
      //     { value: "只" },
      //     { value: "把" },
      //     { value: "枚" },
      //     { value: "瓶" },
      //     { value: "盒" },
      //     { value: "台" },
      //     { value: "吨" },
      //     { value: "千克" },
      //     { value: "米" },
      //     { value: "箱" },
      //     { value: "套" },
      //   ],
      // },
      addForm: {},
      updateForm: {},
      updateVisible: false,
      addProductVisible: false,
      tableData: [], //表格内绑定的数据
      pageTotal: 0, //页数
      pageIndex: 1, //当前在第几页
      pageSize: 10, //前端规定每页显示的数量
      page: 1, //第几页
      size: 10, //每页的数量
    };
  },
  created() {
    this.roleName = localStorage.getItem("roleName");
    this.getList();
  },
  methods: {
    // 删除产品
    del(row) {
      this.$confirm("是否确定删除当前产品?（不可恢复）！！！", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          let params = {
            id: row.row.id,
          };
          let res = productDelete(params);
          res.then((res) => {
            if (res.data.statusCode == "00000") {
              Message.success(res.data.message);
              this.getList();
            } else {
              Message.error(res.data.message);
              this.getList();
            }
          });
        })
        .catch(() => {
          Message.info("已取消删除");
        });
    },
    // 编辑产品
    update(row) {
      this.id = row.row.id;
      this.updateForm = { ...row.row };
      this.updateVisible = true;
    },
    // 编辑产品确定
    updateSubmit() {
      let params = {
        id: this.id,
        productName: this.updateForm.productName,
        productCoding: this.updateForm.productCoding,
        procuctPrice: this.updateForm.procuctPrice,
        productDescribe: this.updateForm.productDescribe,
        status: this.updateForm.status,
        unit: this.updateForm.unit,
      };
      let res = productUpdate(params);
      res.then((res) => {
        let code = res.data.statusCode;
        if (code == "00000") {
          Message.success(res.data);
        } else {
          Message.error(res.data);
        }
        this.updateVisible = false;
        this.getList();
      });
    },
    // 新增产品
    addProduct() {
      this.addForm = {};
      this.addProductVisible = true;
    },
    // 新增产品确定
    addProductSubmit() {
      let params = {
        productName: this.addForm.productName,
        productCoding: this.addForm.productCoding,
        procuctPrice: this.addForm.procuctPrice,
        productDescribe: this.addForm.productDescribe,
        status: this.addForm.status,
        unit: this.addForm.unit,
      };
      let res = productAdd(params);
      res.then((res) => {
        let code = res.data.statusCode;
        if (code == "00000") {
          Message.success(res.data);
        } else {
          Message.error(res.data);
        }
        this.addProductVisible = false;
        this.getList();
      });
    },
    // 展示
    getList() {
      let params = {
        page: this.page,
        size: this.size,
      };
      let res = productSelect(params);
      res.then((res) => {
        this.tableData = res.data.data;
        this.pageTotal = res.data.total;
      });
    },
    // 分页
    handlePageChange(val) {
      this.page = val;
      this.getList();
    },
    handleSizeChange(val) {
      this.size = val;
      this.getList();
    },
    // 转换日期
    getYMDHMS(format, date) {
      if (!date) {
        return "";
      }
      date = new Date(date);
      const dataItem = {
        "Y+": date.getFullYear().toString(),
        "m+": (date.getMonth() + 1).toString(),
        "d+": date.getDate().toString(),
        "H+": date.getHours().toString(),
        "M+": date.getMinutes().toString(),
        "S+": date.getSeconds().toString(),
      };
      Object.keys(dataItem).forEach((item) => {
        const ret = new RegExp(`(${item})`).exec(format);
        if (ret) {
          format = format.replace(
            ret[1],
            ret[1].length === 1
              ? dataItem[item]
              : dataItem[item].padStart(ret[1].length, "0")
          );
        }
      });
      return format;
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-button--success,.el-button--primary {
    color: #FFF;
    background-color: #3278FE;
    border-color: #3278FE;
    &:hover{
      opacity: 0.7;
    }
}
#product {
  header {
    h1 {
      font-weight: normal;
      > span {
        font-size: 18px;
        color: #606369;
      }
    }
    .search {
      display: flex;
      align-items: center;

      .left {
        display: flex;
        align-items: center;
        height: 100%;
        flex-wrap: wrap;
        flex: 1;
        line-height: 30px;


        .left-wrap {
          width: 20%;
          display: flex;
          align-items: center;

          .el-select {
            width: 100%;
          }

          span {
            margin: 4px 10px;
            width: 60px;
            text-align: right;
            align-items: center;
            font-size: 12px;
          }
        }
      }

      .right {
        // margin-top: 20px;
        text-align: right;
        display: flex;
        /* justify-content: space-between; */
        justify-content: flex-end;
        align-items: center;
        height: 100%;
      }
    }
  }
  main {
    .pagination {
      text-align: right;
      margin: 20px 0;
    }
  }
  /deep/ .el-radio__label {
    padding-right: 20px;
    padding-left: 5px;
    box-sizing: border-box;
  }
}
</style>